export enum ACCOUNT_REGISTRATION_PROGRESS {
  PRIVACY_POLICY = 0,
  REGISTRATION = 1,
  STATUS = 2,
}

export const accountRegistrationProgress = [
  {
    title: "Platform Service Agreement",
    tooltip: "Accept the terms and conditions",
  },
  { title: "Registration", tooltip: "Complete the registration form" },
  { title: "Status", tooltip: "Wait for approval status" },
];
